import React from "react";

export default class ReTarget extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    const img = document.createElement('img');
    img.src = 'https://ad.mail.ru/i4345.gif';
    img.setAttribute('style', 'width:0;height:0;position:absolute;visibility:hidden;');
    img.setAttribute('alt', '');
    document.body.appendChild(img);
  }

  render() {
    return null;
  }
}
