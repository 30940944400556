import React, { createContext, useContext, useState, useEffect } from "react";
import { useConfigContext } from "../context";

export const TrackerProvider = ({ children }) => {
  const { tid, cid } = useConfigContext();

  const [teaserIds, setTeaserIds] = useState([]);
  const debouncedTeaserIds = useDebounce(teaserIds, 1000);

  const onTrack = id => {
    setTeaserIds(prevState => [...prevState, id]);
  };

  useEffect(() => {
    if (debouncedTeaserIds.length === 0) {
      return;
    }

    track({
      tid,
      cid,
      teaserIds: debouncedTeaserIds
    });
    setTeaserIds([]);
  }, [tid, cid, debouncedTeaserIds]);

  return (
    <TrackerContext.Provider
      value={{
        onTrack
      }}
    >
      {children}
    </TrackerContext.Provider>
  );
};

const TrackerContext = createContext(undefined);

export const useTracker = () => {
  const context = useContext(TrackerContext);

  if (!context) {
    throw new Error("TrackerContext is not provided");
  }

  return context;
};

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

const track = async ({ cid, tid, teaserIds }) => {
  await fetch(
    `${process.env.REACT_APP_DATA_SRC}/collect_top?obj=${cid}&top_id=${tid}&teaser_ids=${teaserIds.join(",")}`
  );
};
