export const CAMPAIGN_TYPE_EXCHANGE = 1;
export const CAMPAIGN_TYPE_ADVERTISER = 2;

export const MAIN_CLICK_NORMAL = 'a';
export const MAIN_CLICK_BLUR = 'b';
export const MAIN_CLICK_BACK = 'c';
export const MAIN_CLICK_BACK_N = 'd'
export const FEED_CLICK = 'e';
export const DIRECT_CLICK = 'f';
export const MAIN_CLICK_AFTER_RELOAD = 'g';
