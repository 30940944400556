import React from "react";
import { wait, reachGoal } from "../../utils";

import "./index.css";

const PROJECT_ID = `${process.env.REACT_APP_WEBPUSH_PROJECT_ID || ""}`;
const ASK_PERMISSION_ON_VISIT = 1;

export default class SmiWebpush extends React.Component {
  componentDidMount() {
    if (!PROJECT_ID) {
      return;
    }

    downloadScript();

    (window.SmiWebpush = window.SmiWebpush || []).push(() => {
      window.SmiWebpush.init({
        projectId: PROJECT_ID,
        scope: "/"
      });

      shouldTryToSubscribe()
        .then(() => window.SmiWebpush.subscribeByNativePrompt())
        .catch(error => {
          console.log(error);
        });

      // installApp();
    });
  }

  render() {
    return null;
  }
}

function shouldTryToSubscribe() {
  let visitsNum = 0;
  try {
    visitsNum = incrementVisitsNum();
  } catch (e) {}

  if (visitsNum < ASK_PERMISSION_ON_VISIT) {
    return Promise.reject("Not enough visits to ask permission");
  }
  return Promise.resolve();
}

function incrementVisitsNum() {
  let visitsNum = Number(window.localStorage.getItem("visitsNum") || 0);
  if (isNaN(visitsNum)) {
    visitsNum = 0;
  }
  visitsNum += 1;
  window.localStorage.setItem("visitsNum", visitsNum);
  return visitsNum;
}

function installApp() {
  window.addEventListener("beforeinstallprompt", e => {
    console.log("beforeinstallprompt");
    if (e.promt) {
      e.preventDefault();
      wait(1000).then(() => {
        return e.promt();
      });
    }

    if (e.userChoice) {
      e.userChoice.then(({ outcome }) => {
        reachGoal(`pwa_install_${outcome}`);
      });
    }
  });

  window.addEventListener("appinstalled", () => {
    console.log("appinstalled");
    reachGoal("pwa_install_accepted");
  });
}

function downloadScript() {
  const n = document.getElementsByTagName("script")[0];
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = "https://wp.24smi.org/js/smi-webpush-dev.js";
  n.parentNode.insertBefore(s, n);
}
