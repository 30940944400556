import defaultConfig from "./default";
import customConfig from "./custom";
import devConfig from "./dev";

export const REDIRECT_TOP = 'top'
export const REDIRECT_TEASER = 'teaser'
export const REDIRECT_TEASER_TOP = 'teaser_top'

// обновляется тизер
export const FEED_NEWS_CLICK_RELOAD = "reload";

// обновляется вся страница(без перезагрузки)
export const FEED_NEWS_CLICK_RELOAD_PAGE = "reload_page";

export const MAIN_NEWS_CLICK_RELOAD = "reload";

// редирект в топ, если:
// - Тип кампании тизера: 2(advertiser)
// - Или нет главного тизера
export const BACK_REDIRECT_TOP = "top";

// редирект на главный тизер, если:
// - Есть главный тизер
// - Тип кампании тизера: 1(exchange)
export const BACK_REDIRECT_TEASER = "teaser";

// обнлвяем топ без перезагрузки страницы, меняем url на /top, скрываем главную новость, если владка была неактивна n секунд
export const INACTIVE_REDIRECT_TOP = "top";

// делаем редирект на главную новость
export const INACTIVE_REDIRECT_TEASER = "teaser";

// редирект не происходит, если был клик по главной новости
export const INACTIVE_REDIRECT_SKIP_MAIN_CLICK = "main_click";

function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

export function mergeConfig(target, source) {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeConfig(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

export default function getConfig(baseConfig) {
  const config = Object.assign({}, baseConfig, defaultConfig);

  const custom = customConfig[config.tid] || {};

  if (process.env.NODE_ENV === "development") {
    Object.assign(custom, devConfig);
  }

  return mergeConfig(config, custom);
}
