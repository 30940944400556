export default {
  feed_news_click: "reload",
  feed_news_click_timeout: 500,
  main_news_click: "reload",
  main_news_click_timeout: 500,
  back_redirect: {
    advert: "top",
    exchange: "teaser",
    no_news: "top"
  },
  inactive_redirect: {
    advert: "top",
    exchange: "teaser",
    no_news: "top",
    skip: "main_click",
    timeout: 60 * 1000
  }
};
