import React from "react";

export default class YaMetrica extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) // eslint-disable-line no-unused-expressions
    (window, document, "script", "https://cdn.jsdelivr.net/npm/yandex-metrica-watch/tag.js", "ym");
    window.ym(process.env.REACT_APP_YA_COUNTE_ID, "init", {
         clickmap:true,
         trackLinks:true,
         accurateTrackBounce:true
    });
  }

  render() {
    return null;
  }
}
