import React, { useContext } from "react";

export const ConfigContext = React.createContext();
export const CoreContext = React.createContext();

export function useConfigContext() {
  return useContext(ConfigContext);
}

export function useCoreContext() {
  return useContext(CoreContext);
}

export function withConfig(Component) {
  return function C(props) {
    const config = useConfigContext() || {};
    return <Component {...props} config={config} />;
  };
}

export function withCoreContext(Component) {
  return function C(props) {
    const handlers = useCoreContext() || {};
    return <Component {...props} handlers={handlers} />;
  };
}
