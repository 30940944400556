import { useEffect } from 'react';
import { assignUrlParams } from '../utils'
import { DIRECT_CLICK } from '../constants';
import getSubidParams from 'getSubidParams';


const Redirect = ({ url }) => {

  useEffect(() => {
    window.location = assignUrlParams(url, getSubidParams(DIRECT_CLICK));
  }, [url]);

  return null
};

export default Redirect;
