import {
  getUrlParams,
  getPathParams,
  getUserInfo,
  getCookie,
  stringQueryParams,
  buildTeaserUrl,
  replaceImageDomain,
  removeTeaserDomain,
  removeDomain,
  assignUrlParams
} from "utils";

import * as constants from "./constants";
import getSubidParams from "./getSubidParams";

function request(url = "/", opts = {}) {
  const requestParams = {
    method: "GET",
    credentials: "include"
  };
  const queryParamsDefault = {
    host: window.location.origin
  };
  let _opts = Object.assign({}, requestParams, opts);
  let _url = url;

  if (window.ab) {
    queryParamsDefault.ab = window.ab;
  }

  _opts.queryParams = Object.assign({}, queryParamsDefault, _opts.queryParams);

  if (_opts.queryParams) {
    _url +=
      (_url.indexOf("?") === -1 ? "?" : "&") +
      stringQueryParams(_opts.queryParams);
  }

  return fetch(_url, {
    method: _opts.method,
    credentials: _opts.credentials
  })
    .then(response => response.json())
    .then(res => {
      return { data: res };
    });
}

export function loadConfig(params = {}) {
  const uid = getCookie("uid");
  const urlParams = getUrlParams();
  const userInfo = getUserInfo();
  const _params = Object.assign(
    {
      site_id: `${process.env.REACT_APP_SITE_ID || ""}`,
      chash: uid,
      _r: Math.random(),
      timer_reload: 1
    },
    urlParams,
    userInfo,
    params
  );

  if (urlParams.announce_id && !urlParams.reload) {
    _params.action = "in";
  } else {
    _params.action = "top";
  }

  // replace subid or opera revenue
  if (_params.campaign_id == "28665" && _params.tsource) {
    _params.subid2 = _params.tsource;
  }

  let configHost = `${process.env.REACT_APP_DATA_SRC}`;
  if (urlParams.domain) {
    configHost = `//${urlParams.domain}`;
  }

  return new Promise(resolve => {
    const configUrl = `${configHost}/api/`;

    request(configUrl, { queryParams: _params }).then(response => {
      if (response.data.redirect) {
        window.location = assignUrlParams(
          response.data.redirect,
          getSubidParams(constants.DIRECT_CLICK)
        );
      }

      if (response.data.teaser) {
        response.data.teaser.image = `${process.env.REACT_APP_IMAGE_HOST}/400_400/${response.data.teaser.image}`;

        const clickSubIdParams = getSubidParams(constants.MAIN_CLICK_NORMAL);

        response.data.teaser.url = buildTeaserUrl(
          response.data.config.domain,
          response.data.teaser.url,
          {
            ...clickSubIdParams,
            chash: uid,
            click_id: response.data.config.click_id
          }
        );
      }

      resolve(response.data);
    });
  });
}

export function loadCurrency() {
  return request(process.env.REACT_APP_CURRENCY_SRC, {
    credentials: "omit"
  });
}

export async function loadTop(params = {}) {
  const pathParams = getPathParams();
  const userInfo = getUserInfo();

  const uid = getCookie("uid");

  const _params = Object.assign(
    { iw: 400, ih: 400, chash: uid, _r: Math.random() },
    pathParams,
    userInfo,
    params
  );

  let result = [];
  let emptyResponseCnt = 0;

  const num = params.num;

  const dataSrc = `${process.env.REACT_APP_DATA_SRC}`;

  while (result.length < params.num && emptyResponseCnt !== 3) {
    const needToLoad = num - result.length;
    _params.num = needToLoad;

    const response = await request(`${dataSrc}/top`, {
      queryParams: _params
    });

    if (response.data.length === 0) {
      emptyResponseCnt += 1;
    }

    const clickSubIdParams = getSubidParams(constants.FEED_CLICK);

    const teaserUrlParams = {
      ...userInfo,
      ...clickSubIdParams,
      chash: uid,
      click_id: _params.click_id
    };

    response.data.forEach(resp => {
      resp.cat = resp.cat.replace(" - Реклама", "");

      resp.image = replaceImageDomain(resp.image);

      resp.url = buildTeaserUrl(_params.domain, resp.url, teaserUrlParams);
    });

    result = result.concat(response.data);
  }

  return { data: result };
}

export async function loadTopBr(params = {}) {
  const pathParams = getPathParams();
  const userInfo = getUserInfo();

  const uid = getCookie("uid");

  const _params = Object.assign(
    { iw: 400, ih: 400, chash: uid, _r: Math.random() },
    pathParams,
    userInfo,
    params
  );

  const dataSrc = `${process.env.REACT_APP_DATA_SRC}`;

  const response = await request(`${dataSrc}/top_br`, {
    queryParams: _params
  });

  const clickSubIdParams = getSubidParams(constants.FEED_CLICK);

  const teaserUrlParams = {
    ...userInfo,
    ...clickSubIdParams,
    chash: uid,
    click_id: _params.click_id
  };

  response.data.forEach(resp => {
    resp.cat = resp.cat.replace(" - Реклама", "");

    resp.image = replaceImageDomain(resp.image);

    resp.url = buildTeaserUrl(_params.domain, resp.url, teaserUrlParams);
  });

  return { data: response.data };
}

export function loadInformer(informerIds, count, params = {}) {
  let _informerIds = informerIds || [];

  if (typeof informerIds === "string") {
    if (informerIds !== "") {
      _informerIds = informerIds.replace(" ", "").split(",");
    } else {
      _informerIds = [];
    }
  }

  const num = Math.round(count / _informerIds.length);

  const uid = getCookie("uid");

  const requests = _informerIds.map(informerId =>
    request(`${process.env.REACT_APP_DATA_SRC}/informer`, {
      queryParams: Object.assign(
        { num, output: "json", object: informerId, host_id: 1, chash: uid },
        params
      )
    })
  );

  const responses = Promise.all(requests);

  return new Promise((resolve, reject) => {
    responses.then(respList => {
      let result = [];
      for (let r of respList) {
        result = result.concat(r.data.news);
      }
      result.forEach(res => {
        res.cat = res.cat.replace(" - Реклама", "");
        res.url = buildTeaserUrl(null, removeTeaserDomain(res.url), {
          chash: uid,
          click_id: params.click_id,
          prev_campaign_id: params.prev_campaign_id
        });
        return res;
      });
      resolve(result);
    });
  });
}

export async function loadSsp(params, config) {
  const pathParams = getPathParams();
  const userInfo = getUserInfo();

  const uid = getCookie("uid");

  const queryParams = Object.assign(
    { iw: 400, ih: 400, chash: uid, _r: Math.random() },
    pathParams,
    userInfo,
    params
  );

  const sspSrc = `${process.env.REACT_APP_SSP_SRC}`;

  const response = await request(`${sspSrc}/rtb/v2/top`, { queryParams });

  const { news, settings } = response.data;

  const clickSubIdParams = getSubidParams(constants.FEED_CLICK);

  const adUrlParams = {
    ...userInfo,
    ...clickSubIdParams,
    chash: uid,
    click_id: config.click_id
  };

  news.forEach(ad => {
    // adveo teaser
    if (ad.id) {
      ad.image = replaceImageDomain(ad.image);
      ad.url = buildTeaserUrl(config.domain, removeDomain(ad.url), adUrlParams);
    }
    return ad;
  });

  return { news, settings };
}

export async function sendBillingNotice(url) {
  return new Promise(resolve => {
    const img = document.createElement("img");
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
}

export function adblockDetect() {
  return new Promise(resolve => {
    const script = document.createElement("script");

    script.onload = () => resolve(false);
    script.onerror = () => resolve(true);

    script.type = "text/javascript";
    script.src = process.env.PUBLIC_URL + "/js/ads.js";

    document.body.appendChild(script);
  });
}

export function trackDetect() {
  return new Promise(resolve => {
    const img = document.createElement("img");
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = "//apps.facebook.com/favicon.ico";
  });
}
