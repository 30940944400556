import React from 'react'

export default class LiRuCounter extends React.Component {

    componentDidMount() {
        new Image().src = "https://counter.yadro.ru/hit?r" +
        escape(document.referrer) + ((typeof(window.screen) == "undefined") ? "" :
        ";s" + window.screen.width + "*" + window.screen.height + "*" + (window.screen.colorDepth ?
            window.screen.colorDepth : window.screen.pixelDepth)) + ";u" + escape(document.URL) +
        ";" + Math.random();
    }

    render() {
        return null;
    }
}
