import { CAMPAIGN_TYPE_ADVERTISER, CAMPAIGN_TYPE_EXCHANGE } from "../constants";
import { REDIRECT_TOP, REDIRECT_TEASER, REDIRECT_TEASER_TOP } from "../config";

const BACK_REDIRECT_INFO_KEY = "back_redirect_info";

export class BackRedirectDetect {
  constructor(mainNews, config) {
    this.mainNews = mainNews;
    this.config = config;
  }

  getRedirectInfoFromNews() {
    if (!this.mainNews) {
      return this.config.back_redirect["no_news"];
    }

    const typeMap = {
      [CAMPAIGN_TYPE_ADVERTISER]: "advert",
      [CAMPAIGN_TYPE_EXCHANGE]: "exchange"
    };

    const fieldName = typeMap[this.mainNews.campaign_type];

    return this.config.back_redirect[fieldName];
  }

  setInfo() {
    sessionStorage.setItem(
      BACK_REDIRECT_INFO_KEY,
      this.getRedirectInfoFromNews()
    );
  }
}

export class BackRedirectInfo {
  getInfo() {
    return sessionStorage.getItem(BACK_REDIRECT_INFO_KEY);
  }

  isTopRedirect() {
    return this.getInfo() === REDIRECT_TOP;
  }

  isTeaserRedirect() {
    return this.getInfo() === REDIRECT_TEASER;
  }

  isTeaserTopRedirect() {
    return this.getInfo() === REDIRECT_TEASER_TOP;
  }
}
